<template>
    <div>

        <div class="d-flex justify-content-center h-100 content">
            <b-row class="w-100" style="max-width: 1200px;">
                <b-col md="12">
                    <h1 class="mt-3" style="font-size: 25px; font-weight: 600;">Digital Spend Optimization (DSO)</h1>
                    <h2 style="font-size: 20px;">Eliminate common data blind spots and gain valuable insights into the digital shopper's journey.</h2>
                </b-col>

                <b-col md="8" class="mt-4-lg">

                    <div class="mt-4">
                        <p>When the only data measured are clicks, impressions, traffic and spend, it's easy for dealerships to question their digital advertising efficacy. DSO is built to measure the full online shopping journey to sale and answer critical questions to optimize spend beyond traditional measurements:</p>
                        <ul>
                            <li>Identify anonymous digital shoppers</li>
                            <li>Match shoppers to sales</li>
                            <li>Create digital advertising attribution models</li>
                            <li>Measure efficacy of campaigns and spending</li>
                        </ul>
                        <p>Dealerships enrolled in ADDP 2.0 not only get the DSO program but also Acura's fully funded <router-link to="/about-enterprise-services" style="">Enterprise Services</router-link> to become a digital powerhouse.</p>
                        
                        <hr class="mt-4">
                        
                        <h3 class="mt-4" style="font-size: 20px;">Why J.D. Power</h3>
                        <ul>
                            <li>Global leader in consumer insights, advisory services, data, and analytics</li>
                            <li>A pioneer in the use of big data, artificial intelligence (AI) and algorithmic modeling capabilities to understand consumer behavior</li>
                            <li>J.D. Power has been delivering incisive industry intelligence on customer interactions with brands and products for more than 50 years</li>
                            <li>Proven within the industry to collect the data and insights needed to help move the electrification of vehicles forward. J.D. Power E-Vision Strategy</li>
                            <li>Hyper-focused on Customer Experience through their unbiased consumer experience insights</li>
                        </ul>

                    </div>

                </b-col>

                <b-col md="4">
                    
                </b-col>
            </b-row>
        </div>
    </div>

</template>

<script>

export default {
    name: 'digital-spend-optimization',
    components: {

    },

    metaInfo: {
        title: 'Digital Spend Optimization'
    },

    data() {
        return {

        };
    },

    methods: {

    },

    computed: {

    },
}



</script>

<style>

.content {
    margin-top: 20px;
}

@media screen and (max-width: 768px) {

    .content {
        margin-top: 0px;
        padding-top: 60px;
    }

}
</style>